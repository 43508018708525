<template>
  <div class="wrapper">
      <div class="content">
          <div class="stats" id="stats">
              <div class="container gradient">
                  <h1>+
                      <number
                      
    ref="number1"
	:from="0"
	:to="65"
	:duration="3"
    :delay="1.5"
    easing="Power1.easeOut"/>
                  </h1>
                  <p>empresas</p>
              </div>
              <div class="container gradient">
                  <h1>+<number
                
    ref="number3    "
	:from="0"
	:to="25"
	:duration="3"
    :delay="1.5"
    easing="Power1.easeOut"/></h1>
                  <p>gWh</p>
              </div>
              <div class="container gradient">
                  <h1>+<number
                  
    ref="number3"
	:from="0"
	:to="20"
	:duration="3"
    :delay="1.5"
    easing="Power1.easeOut"/>%</h1>
                  <p>ahorro medio</p>
              </div>
          </div>
          <h2 style="margin-top: 90px;">
              Asesoramiento transparente <br> basado en datos para una compra energética eficiente.
<!-- 
              SAVE ON YOUR ENERGY <br> CONSUMPTION WITH SOLUTZ -->
          </h2>
          <p style="margin-bottom: 90px;">
             Solutz pone a disposición de empresas la tecnología y el conocimiento necesarios para minimizar costes y ahorrar tiempo. Además, ofrecemos energía renovable certificada y nuestros servicios no tienen coste alguno. 
<br> 
¿A que esperas para unirte?

          </p>
          <div class="" data-animation="downUp">
              <img    style="max-width: 90%;" src="@/assets/images/illustrations/flowchart_mercados_y_empresas_1.svg" alt="">
          </div>
          <router-link to="/contacto" ><button class="mobile-btn" style="margin-bottom: 40px;">Únete ahora</button></router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  data() {
      return {
          triggerHeight: null
      }
  },
//   mounted() {
//       this.triggerHeight = document.getElementById('stats').offsetTop
//       console.log('pos', this.triggerHeight, window.innerHeight )
//       window.addEventListener("scroll", this.checkHeight())
      
//     },
  methods:{
      playAnimation: function(){
            this.$refs.number1.play()
        },
        checkHeight: function(){
            // function () {
          console.log(window.scrollY, ( window.innerHeight) > document.getElementById('stats').offsetTop)
          if(window.innerHeight > (document.getElementById('stats').offsetTop + 150))
            {
                console.log('start')
                this.$refs.number1.play()
            }      
    // }
        }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
.wrapper{
    background-image: url(../assets/images/wavy_background_2sided.svg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    .content{
        padding-top: 130px;
        padding-bottom: 100px;
        color: $dirty-white;
        .stats{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  .container{
      width: 180px;
      margin: 20px;
      box-shadow: 2px 2px 20px 1px rgba(65, 108, 213, 0.6);
        h1{
            margin: 0px;
        }
        @media screen and (max-width: $res-width-2)  {
          width: 160px;
        }
        @media screen and (max-width: $res-width-3)  {
          width: 130px;
        }
    }
}
    }
}
  
</style>
