<template>
  <div class="wrapper">
      <div class="content">
          <div class="logo">
              <img src="@/assets/images/solutz_logo_white_horizontal.svg" alt="">
          </div>
          <div class="menu">
              <router-link to="/condiciones-legales">Aviso Legal</router-link>
              <router-link to="/faq">FAQ</router-link>
              <router-link to="/privacidad">Política de Privacidad</router-link>
              <router-link to="/contacto">Contacto</router-link>
          </div>
          <div>
              <a href="https://es.trustpilot.com/review/solutz.com"><img style="width: 150px;" src="@/assets/images/trustpilot-vector-logo.svg" alt="Trustpilot - Solutz"></a>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
    .wrapper{
        background-color: $blue;
        box-shadow: 0px 5px 30px 10px rgba(65, 108, 213, 0.6);;
        // height: 250px;
        .content{
            display: flex;
            align-items: center;
            justify-content: space-between; 
            padding: 40px 0 40px 0;
             @media screen and (max-width: 650px)  {
                    flex-direction: column;
                }
            .logo{
                width: 200px;
                img{
                    width: 200px;
                    @media screen and (max-width: 650px)  {
                    width: 150px;
                    margin-bottom: 40px;
                }
                }
            }
            .menu{
                
                width: 65%;
                display: flex;
                justify-content: space-evenly;
                
                @media screen and (max-width: $res-width-2)  {
                    flex-direction: column;
                }
                a{
                    margin: 8px;
                    color: white;
                    text-decoration: none;
                    :visited{
                        color: white;
                    }
                }
            }
        }
    }
</style>
