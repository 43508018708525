<template>
  <div class="wrapper">
      <div class="content">
          <h2>¿Por qué nuestro agregador?
</h2>
          <div class="reasons">
              <!-- <div class="container">
                  <h3>Independencia</h3>
                  <img src="@/assets/images/illustrations/independencia.svg" alt="">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.ea commodo consequat. Duis aute irur</p>
                    <button>Solutions</button>
              </div>
              <div class="container">
                  <h3>Independencia</h3>
                  <img src="@/assets/images/illustrations/conocimiento.svg" alt="">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.ea commodo consequat. Duis aute irur</p>
                    <button>Solutions</button>
              </div>
              <div class="container">
                  <h3>Independencia</h3>
                  <img src="@/assets/images/illustrations/herramientas.svg" alt="">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.ea commodo consequat. Duis aute irur</p>
                    <button>Solutions</button>
              </div> -->
              <div class="container" data-animation="leftRight">
                  <div class="text">
                    <h3>Independencia</h3>
                    <img src="@/assets/images/illustrations/independencia.svg" alt="">
                    <p>Somos una empresa independiente que vela en todo momento por los intereses de sus clientes. Por volumen conseguimos una posición con mayor poder de negociación frente a las empresas del sector.</p>
                  </div>
                  <div class="illustration-mobile">
                    <img src="@/assets/images/illustrations/independencia.svg" alt="">
                  </div>
                  <div class="btn">
                    <router-link to="/nosotros"><button>+info</button></router-link>
                  </div>
              </div>
              <div class="container" data-animation="rightLeft">
                  <div class="text">
                    <h3>Conocimiento</h3>
                    <img src="@/assets/images/illustrations/conocimiento.svg" alt="">
                    <p>Nuestro equipo cuenta con el conocimiento y la experiencia para guiar y asesorar a nuestros clientes en materia energética, desde la compra hasta la generación.</p>
                  </div>
                  <div class="illustration-mobile">
                    <img src="@/assets/images/illustrations/conocimiento.svg" alt="">
                  </div>
                  <div class="btn">
                    <router-link to="/nosotros"><button>+info</button></router-link>
                  </div>
              </div>
              <div class="container" data-animation="leftRight">
                  <div class="text">
                    <h3>Herramientas</h3>
                    <img src="@/assets/images/illustrations/herramientas.svg" alt="">
                    <p>Nuestra tecnología nos permite: 1. Encontrar la opción de compra que minimiza el consumo basándonos en datos. 2. Gestionar la demanda de forma activa, resultando en un mayor ahorro y menor cantidad de emisiones de carbono.</p>
                  </div>
                  <div class="illustration-mobile">
                    <img src="@/assets/images/illustrations/herramientas.svg" alt="">
                  </div>
                  <div class="btn">
                    <router-link to="/nosotros"><button>+info</button></router-link>
                  </div>
              </div>
              <div class="container" data-animation="rightLeft">
                  <div class="text">
                    <h3>Transparencia</h3>
                    <img src="@/assets/images/illustrations/transparencia.svg" alt="">
                    <p>Mantenemos con todos nuestros clientes una política estricta de honestidad y transparencia para fortalecer la confianza.  Creemos firmemente que siguiendo esta política creamos valor a largo plazo por la satisfacción de nuestros clientes.</p>
                  </div>
                  <div class="illustration-mobile">
                    <img src="@/assets/images/illustrations/transparencia.svg" alt="">
                  </div>
                  <div class="btn">
                    <router-link to="/nosotros"><button>+info</button></router-link>
                  </div>
              </div>
              <!-- <div class="container full">
                <div class="container-content">
                  <h3>Ventajas del Agragador Independiente</h3>
                  <p>
                    La agregación de demanda tiene ventajas claras para el consumidor final y para la gestionabilidad de la propia red, estas son algunas de ellas:
                    <ul>
                      <li>Mejora de condiciones económicas ya que gracias la volumen gestionado obtenemos un mayor poder de negociación.</li>
                      <li>Energía 100% con certificado de origen renovable.</li>
                      <li>Intermediación favorable para el consumidor: Protección ante conflictos con empresas comercializadoras y distribuidoras, nos posicionamos contigo siempre y simplificamos procesos con total transparencia.</li>
                      <li>Tecnología para la gestión de la demanda energética: Innovamos constantemente para poder ofrecer el mejor sistema de gestión de demanda disponible.</li>
                    </ul>
                  </p>
                </div>
              </div> -->
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'WhyUs',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/assets/styles/variables"; 
.reasons{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 80px;
    .container{
        width: 43%;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .text{
          img{
          height: 150px;
          @media screen and (max-width: $res-width-2) and (min-width: $res-width-3) {
            display: none;
          }
          }
           @media screen and (max-width: $res-width-2) and (min-width: $res-width-3) {
            width: 60%;
            padding-right: 20px;
          }
        }
        .illustration-mobile{
          height: 150px;
          width: calc(40% - 20px);
          display: none;
          @media screen and (max-width: $res-width-2) and (min-width: $res-width-3) {
            display: flex;
            justify-content: center;
            img{
              height: inherit;
            }
          }
        }
        @media screen and (max-width: $res-width-2)  {
          width: 100%;
          // padding: 0 5% 0 5%;
        }
      .btn{
        width: 100%;
            margin: 20px 0px;
      }
      &.full{
        width: 100%;
        text-align: left;
        h3{
          text-align: center;
        }
      }
    }
    // .container-full{
    //     padding-top: 0px;
    //     width: 80%;
    //     margin: auto;
    //     margin-top: 100px;
    //     padding-bottom: 35px;
    //     .container-content{
    //         display: flex;
    //         justify-content: space-between;
    //         flex-wrap: wrap;
    //         margin: 0 30px;
    //         align-items: center;
    //         .text{
    //             width: 100%;
    //             text-align: left;
    //             h3{
    //                 margin-top: 0px;
    //             }
    //         }
    //     }
      
    // }
}
</style>
