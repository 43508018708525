

<template>
  <div class="wrapper">
    <div class="content">
      <div class="text animate animated" data-animation="leftRight" data-animation-delay="200">
        <h1 style="margin-bottom: 0px;">
          Agregador de demanda <br> energética
          <!-- <br>
          DEL ELLECIÓN! -->
        </h1>
        <h3 style="font-weight: 300; font-size: 1.4rem; margin-top: 0px; color:#323842;">Innovamos para reducir tu consumo</h3>
        <!-- <button style="margin-top:60px>únete ahora</button> -->
        <router-link to="/contacto" ><button style="margin-top:60px; text-decoration: none;">Quiero unirme</button></router-link>
      </div>
      <div class="illustration animate animated" data-animation="rightLeft" data-animation-delay="200">
        <img src="@/assets/images/illustrations/homepage_illustration.svg" alt="">
      </div>
      <router-link to="/contacto" class="animate animated" data-animation="leftRight" data-animation-delay="200"><button class="mobile-btn">Quiero unirme</button></router-link>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cover',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
  .wrapper{
    .content{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    .text{
      margin-top: 40px;
      width: 85%;
      text-align: left;

       @media screen and (max-width: 999px)  {
        width: 90%;
        // text-align: center;
        margin-top: 0px;
      }
      @media screen and (max-width: 850px)  {
        h1{
          font-size: 25pt;
          line-height: 35pt;
          kerning: -30pt;
        }
      }
      @media screen and (max-width: 660px)  {
        button{
          display: none;
        }
      }
    }
    .illustration{
      position: absolute;
      right: 0;
      width: 55%;
      @media screen and (max-width: 999px)  {
        // position: relative;
        // margin: auto;
        margin-top: 20px;
      }
      @media screen and (max-width: 660px)  {
        position: relative;
        width: 100%;
        right: -18%;
        margin-top: -10px;
      }
    }
    .mobile-btn{
      margin: auto;
      display: none;
      margin-top: 20px;
      text-decoration: none;
      @media screen and (max-width: 660px)  {
        display: block;
      }
    }
  }
  @media screen and (max-width: 999px)  {
        margin-bottom: 10px;
      }
  }
</style>
