import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/nosotros',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/soluciones',
    name: 'About',
    component: () => import('../views/Solutions.vue')
  },
  {
    path: '/contacto',
    name: 'About',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/faq',
    name: 'About',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/condiciones-legales',
    name: 'Legal',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'Privacidad',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/ley-de-discapacidades',
    name: 'Ley de Discapacidades',
    component: () => import('../views/Discapacidades.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes
})

export default router
