<template>
  <div id="app">
    <Navbar />
    <router-view class="router"/>
    <Footer />
    <Sidebar />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'

export default {
   name: 'App',
  components: {
    Navbar,
    Footer,
    Sidebar
  },
  mounted() {
    window.addEventListener("scroll", function () {
      const animatedEls = document.getElementsByClassName('animate')
      const viewHeight = window.scrollY + window.innerHeight
      animatedEls.forEach( el =>{
        var animationOffset = 100
        const additionalOffset = el.getAttribute('data-animation-offset')
        if(additionalOffset) { animationOffset += (additionalOffset*1)}
        if(el.offsetTop < (viewHeight-animationOffset)) {
          el.classList.add('animated')
        }
      })
    })
    }
}
</script>

<style lang="scss">

// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;800&display=swap');
@import "@/assets/styles/variables"; 

#app {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $blue;
  background-color: $dirty-white;
  overflow-x: hidden;
  .wrapper{
    width: 100%;
    .content{
      width: 70vw;
      max-width: 900px;
      margin: auto;
      @media screen and (max-width: $tablet-width) {
      width: 90%;
    }
    }
  }

}
h1{
  font-size: 32pt;
  line-height: 42pt;
  letter-spacing: -1.7px;
}
h2{
  text-transform: uppercase;
   font-size: 25pt;
    line-height: 32pt;
    letter-spacing: -1.7px;
}

button{
  border-radius: 15px;
  width: 225px;
  height: 45px;
  outline: none;
  border: none;
  box-shadow: 3px 3px 16px #67d6ce;
  background-color: $turquoise;
  text-transform: uppercase;
  color: $gray-dark;
  letter-spacing: 2px;
  cursor: pointer;
  &:hover{
    background-color: $gray-dark;
    box-shadow: 3px 3px 16px #323842;
    color: $dirty-white
  }
  &:focus{
    box-shadow: none;
  }
}

.container{
    width: fit-content;
    border-radius: 15px;
    padding: 15px 20px;
    background-color: $gray-medium;
    &.gradient{
      background-image: linear-gradient(50deg, $blue 50%, $turquoise);
      color: $dirty-white;
    }
}

p{

}

.router{
  padding-top: 120px;
  width: 100vw;
}

.animate{
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.animated{
  opacity: 1;
  &[data-animation="downUp"]{
    animation: downUp 0.5s;
  }
  &[data-animation="leftRight"]{
    animation: leftRight 0.5s;
  }
  &[data-animation="rightLeft"]{
    animation: rightLeft 0.5s;
  }
  &[data-animation-delay="100"]{
    animation-delay: 100ms;
    transition-delay: 100ms;
  }
  &[data-animation-delay="200"]{
    animation-delay: 200ms;
    transition-delay: 200ms;
  }
  &[data-animation-delay="300"]{
    animation-delay: 300ms;
    transition-delay: 300ms;
  }
  &[data-animation-delay="400"]{
    animation-delay: 400ms;
    transition-delay: 400ms;
  }
}

@keyframes downUp {
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes leftRight {
  0% { transform: translate3d(-200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes rightLeft {
  0% { transform: translate3d(200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}


/* #nav {
  padding: 30px;
} */

/* #nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
