<template>
  <div class="home">
    <Cover />
    <Intro />
    <HowItWorks />
    <WhyUs />
    <Reviews />
  </div> 
</template>

<script>
// @ is an alias to /src
import Cover from '@/components/Cover.vue'
import Intro from '@/components/Intro.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import WhyUs from '@/components/WhyUs.vue'
import Reviews from '@/components/Reviews.vue'

export default {
  name: 'Home',
  components: {
    Cover, 
    Intro,
    HowItWorks,
    WhyUs,
    Reviews
  },
  mounted () {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }
}
</script>

<style scoped lang="scss">
  .home{

  }
</style>
