<template>
  <div class="navWrapper" id="navWrapper">
    <div class="navbar" id="nav">
      <div class="logo">
        <router-link to="/"><img
          class="logo"
          src="@/assets/images/solutz_logo_gradient_horizontal.svg"
          alt=""
        /></router-link>
        <!-- 
        <img
          class="logoText"
          src="@/assets/images/solutz-textOnly.svg"
          alt=""
        /> -->
      </div>
      <div class="menu">
        <router-link class="link" to="/"> Home </router-link>
        <router-link class="link" to="/nosotros"> Nosotros </router-link>
        <router-link class="link" to="/soluciones"> Soluciones </router-link>
        <router-link class="link" to="/contacto"> Contacto </router-link>
        <div class="burger" @click="toggleSidebar()">
          <img src="@/assets/images/icon_hamburger_menu.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      lastScroll: 0,
      navBar: document.getElementById("nav"),
    };
  },
  mounted() {
    // this.navBar = document.getElementById("nav")
    window.addEventListener("scroll", function () {
      const scrollNow = window.scrollY;
      // console.log("scroll", scrollNow, this.lastScroll);
      if (scrollNow > this.lastScroll && scrollNow > 50) {
        document.getElementById("nav").classList.add("slideUp");
        console.log("add up");
      } else {
        document.getElementById("nav").classList.remove("slideUp");
      }
      this.lastScroll = document.documentElement.scrollTop;
      // if (
      //   document.body.scrollTop > 50 ||
      //   document.documentElement.scrollTop > 50
      // ) {
      //   document.getElementById("nav").classList.add("scrolled");
      // } else {
      //   document.getElementById("nav").classList.remove("scrolled");
      // }
    });
  },
  methods: {
    toggleSidebar: function () {
      console.log('toggleing')
      document.getElementById("sidebar").classList.toggle("active");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/variables";
.navWrapper {
  position: absolute;
  left: 0;
  top: 0;
  &.slideUp {
    top: -180px;
  }
  .navbar {
    display: flex;
    width: 70vw;
    flex-direction: row;
    z-index: 12;
    height: 110px;
    transition: 0.5s;
    position: fixed;
    padding: 0 15vw 0 15vw;
    top: 0;
    // background-color: $gray-medium;
    background-image: url(../assets/images/fading_menu_bar.svg);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 150px;
      img {
        width: 150px;
      }
    }
    .menu {
      width: 65%;
      display: flex;
      justify-content: space-evenly;
      @media screen and (max-width: $res-width-3)  {
          justify-content: flex-end;
        }
      a {
        color: $gray-dark;
        text-decoration: none;
        :visited {
          color: white;
        }
        &.is-active{
          color: $turquoise;
          font-weight: 700;
        }
      }
      .link{
        @media screen and (max-width: $res-width-3)  {
          display: none;
        }
      }
      .burger{
        display: none;
        width: 50px;
        @media screen and (max-width: $res-width-3)  {
          display: block;
        }
      }
    }
    // .logo {
    //   height: 80px;
    //   transition: height ease-out 0.5s;
    // }
    // .logoText {
    //   height: 28px;
    // }
    transition: all 0.5s ease-out;
    &.slideUp {
      top: -110px;
    }
    @media screen and (max-width: $tablet-width) {
      width: 90vw;
      padding: 0 5vw 0 5vw;
    }

    // &.scrolled {
    //   height: 80px;
    //   background-color: $dirty-white;
    //   .logo {
    //     height: 50px;
    //   }
    // }
  }
}
</style>