<template>
  <div class="wrapper">
      <div class="content">
          <h2>Opiniones de nuestros clientes</h2>
          <div class="reviews">
              <div class="container">
                  <div class="head">
                      <div class="logo">
                          <img src="@/assets/images/bullseye.png" alt="">
                      </div>
                      <div class="text">
                          <h3>BullsEye Leads</h3>
                          <!-- <p>Seventeen Marketing&Design</p> -->
                          <div class="stars">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </div>
                      </div>
                  </div>
                  <div>
                      <p>
                          Han sido transparentes con nosotros desde el primer momento y han cumplido con los resultados esperados. Hemos comparado precios con otras ofertas y nos da tranquilidad saber que estamos ahorrando sin tener que dedicarle apenas tiempo a la energía.
                      </p>
                  </div>
              </div>
              <div class="container">
                  <div class="head">
                      <div class="logo">
                          <img src="@/assets/images/Userlyetics.png" alt="">
                      </div>
                      <div class="text">
                          <h3>Userlytics</h3>
                          <!-- <p>Seventeen Marketing&Design</p> -->
                          <div class="stars">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </div>
                      </div>
                  </div>
                  <div>
                      <p>
                          Solutz nos ofrece un servicio inmejorable a la hora de realizar cualquier gestión. Además hemos validado que los ahorros que estiman se acercan mucho a los reales. No podemos estar más contentos con esta colaboración, es realmente increíble el servico que dan sin coste.
                      </p>
                  </div>
              </div>
              <div class="container">
                  <div class="head">
                      <div class="logo">
                          <img src="@/assets/images/mistral_trans.png" alt="">
                      </div>
                      <div class="text">
                          <h3>Mistral SOCIMI</h3>
                          <!-- <p>Seventeen Marketing&Design</p> -->
                          <div class="stars">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </div>
                      </div>
                  </div>
                  <div>
                      <p>
                          Nuestra experiencia con Solutz ha sido inmejorable desde el primer momento, estudiaron nuestro caso y consiguieron que ahorrásemos un 30% , son cercanos y agradables a la par que profesionales y eficientes. ¡100% recomendable!
                      </p>
                  </div>
              </div>
              <!-- <div class="container">
                  <div class="head">
                      <div class="logo">
                          <img src="" alt="">
                      </div>
                      <div class="text">
                          <h3>Laszlo Szucs</h3>
                          <p>Seventeen Marketing&Design</p>
                          <div class="stars">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="far fa-star"></i>
                          </div>
                      </div>
                  </div>
                  <div>
                      <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi, ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                      </p>
                  </div>
              </div> -->
          </div>
          <div style="margin-top: 50px; margin-bottom: 100px;">
                            <router-link to="/contacto"><button class="mobile-btn">Contacta Solutz</button></router-link>

          </div>
          <div style="margin-bottom: 100px">
              <h3>Proveedor de servicios energéticos acreditado por</h3>
                <img style="max-width: 40vw; width: 450px;" src="@/assets/images/idae.svg" alt="">
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Reviews',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
.reviews{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
        .container{
        width: 43%;
        margin-top: 30px;
        @media screen and (max-width: $res-width-2)  {
            width: 100%;
        }
        .head{
            display: flex;
            flex-wrap: wrap;
            .logo{
                width: 100px;
                height: 100px;
                box-shadow: 1px 1px 10px 1px rgba(208, 211, 219, 0.6);
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                img{
                    max-width: 95px;
                    max-height: 95px;
                    object-fit: contain;
                }
                 @media screen and (max-width: $res-width-4)  {
                     margin: auto;
                 }
            }
            .text{
                text-align: left;
                margin-left: 15px;
                position: relative;
                width: calc(100% - 130px);
                @media screen and (max-width: $res-width-4)  {
                    width: 100%;
                    // min-height: 100px;
                    text-align: center;
                }
                h3, p{
                    margin: 0px;
                    color: $blue;
                }
                .stars{
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    color: $turquoise;
                     @media screen and (max-width: $res-width-4)  {
                        position: relative;
                        margin-top: 15px;
                    }
                    i{
                        font-size: 1.2rem;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
</style>
