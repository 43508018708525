<template>
  <div class="wrapper">
      <div class="content">
        <h2>¿Cómo funciona?
</h2>
        <div class="steps">
            <div class="container" data-animation="downUp">
                <div class="number">1</div>
                <div class="container-content">
                    <div class="text">
                        <h3>Definimos la oportunidad</h3>
                        <p>
                            Extraemos la información relevante e introducimos los datos en nuestro programa de optimización de compra. 
                            Se evalúa la situación actual y se compara con el escenario ideal, conviene mantener una conversación para conocer mejor los hábitos y previsiones de consumo de cada empresa.
                            Elaboramos una propuesta con recomendaciones y un plan de acción. De haber conformidad, se comienza una colaboración.
                        </p>
                    </div>
                    <div class="image-side">
                        <img src="@/assets/images/illustrations/how_it_works_scopes.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="container" data-animation="downUp">
                <div class="number">2</div>
                <div class="container-content">
                    <div class="text">
                        <h3>Gestión de compra</h3>
                        <p>
                            Al agregar los consumos de muchas empresas conseguimos negociar precios más competitivos.
                            Optimizamos los parámetros de compra energética.
                            Analizamos anomalías y gestionamos reclamaciones. 
                            Hacemos propuestas de proyectos para reducir el consumo
                        </p>
                    </div>
                    <div class="image-side">
                        <img src="@/assets/images/illustrations/how_it_works_guestion_de_compra.svg" alt="">
                    </div>
                    <!-- <div class="text-full">
                        <p>
                            <strong>Recopilemos información</strong>
                            <br>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi.
                        </p>
                        <p>
                            <strong>Recopilemos información</strong>
                            <br>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi.
                        </p>
                        <p>
                            <strong>Recopilemos información</strong>
                            <br>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi.
                        </p>
                        <p>
                            <strong>Recopilemos información</strong>
                            <br>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi.
                        </p>
                    </div> -->
                </div>

            </div>
            <div class="container" data-animation="downUp">
                <div class="number">3</div>
                <div class="container-content">
                    <div class="text-full">
                        <h3>Optimización continua</h3>
                        <p>
                            Nuestro algoritmo de control genera recomendaciones y alertas automáticas cuando detecta cambios en los hábitos de consumo o anomalías. 
Nuestro equipo sigue de cerca los cambios normativos para informar de oportunidades y riesgos relevantes.

                        </p>
                    </div>
                    <div class="image-full">
                        <img src="@/assets/images/illustrations/howitworks_optimalisacion_continua.svg" alt="">
                    </div>
                </div>

            </div>
        </div>
        <div style="margin: 50px 0;">
              <router-link to="/contacto"><button class="mobile-btn">Contacta Solutz</button></router-link>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/assets/styles/variables"; 

.steps{
    .container{
        padding-top: 0px;
        width: 80%;
        margin: auto;
        margin-top: 100px;
        padding-bottom: 35px;
        .number{
            width: 50px;
            height:  50px;
            background-color: $blue;
            position: relative;
            border-radius: 50%;
            top: -25px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            color: white;
            font-weight: 800;
            margin: auto;
            box-shadow: 2px 2px 20px 1px rgba(65, 108, 213, 0.6);
        }
        .container-content{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0 30px;
            align-items: center;
            .text{
                width: 60%;
                text-align: left;
                h3{
                    margin-top: 0px;
                }
                @media screen and (max-width: $res-width-3)  {
                    width: 100%;
                }
            }
            .text-full{
                width: 100%;
                text-align: left;
                h3{
                    margin-top: 0px;
                }
            }
            .image-side{
                width: 30%;
                @media screen and (max-width: $res-width-3)  {
                    width: 50%;
                    margin: auto;
                }
            }
            .image-full{
                width: 100%;
                margin: auto;
            }
        }
      
    }
}

</style>
